import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './style.module.scss'
import { ReactComponent as ArrowIcon } from './assets/Arrow.svg'
import { useTranslation } from 'react-i18next'

const FAQItem: React.FC<{
    title: string
    description: string
}> = ({ title, description }) => {
    const [active, setActive] = useState(false)
    const activeSectionStyles = active
        ? styles.FAQSection__itemDescription_active
        : ''
    const activeArrowStyles = active ? styles.FAQSection__arrowIcon_active : ''

    const arrowButtonClickHandler = () => {
        setActive(!active)
    }

    const t = useTranslation()

    return (
        <div className={styles.FAQSection__item}>
            <div
                className={styles.FAQSection__titleContainer}
                onClick={arrowButtonClickHandler}
            >
                <p className={styles.FAQSection__titemTitle}>{title}</p>
                <ArrowIcon
                    className={`${styles.FAQSection__arrowIcon} ${activeArrowStyles}`}
                />
            </div>
            <p
                className={`${styles.FAQSection__itemDescription} ${activeSectionStyles}`}
            >
                {description}
            </p>
        </div>
    )
}

export const FAQBlock_shadowgpt: React.FC = () => {
    const location = useLocation()

    const { t, ready } = useTranslation()

    const list = t('faq.faq_shadowgpt', { returnObjects: true })

    return (
        <section className={styles.FAQBlock}>
            <div
                className={'app-container ' + styles.FAQBlock__positionWrapper}
            >
                <h2>{t(`faq.compTitle`)}</h2>
                <p className={styles.FAQBlock__subTitle}>{t(`faq.compDesc_shadowgpt`)}</p>
                <div className={styles.FAQSection}>
                    <div className={styles.FAQSection__blurMask}></div>
                    {location.pathname === '/' && (
                        <img
                            alt=""
                            src={require('../ChooseUsSection/assets/backgroundVector1.webp')}
                            className={styles.FAQBlock__vector1}
                        />
                    )}
                    <div className={styles.FAQSection__wrapper}>
                        {/* @ts-ignore */}
                        {list.length && ready
                            ? //@ts-ignore
                              list.map(({ title, description }, i) => (
                                  <FAQItem
                                      title={`${i + 1}. ${title}`}
                                      description={description}
                                  />
                              ))
                            : null}
                    </div>
                </div>
            </div>
        </section>
    )
}
