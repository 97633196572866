import React from 'react'
import { HumanizerInput, TransparentText } from '@components'
import styles from './style.module.scss'
import { ReactComponent as ShadowChatIcon } from './assets/img/ShadowChat.svg'

import background from './assets/img/1st_screen.webp'
import sparkle from './assets/img/sparkle.svg'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import i18next from 'i18next'

export const Humanizer_bypass: React.FC = () => {
    const navigate = useNavigate()

    const { t } = useTranslation()
    return (
        <div className={styles.Humanizer}>
            <div className={styles.Humanizer__content}>
                <div className={styles.Humanizer__backgroundNoise} />
                <div
                    className={`app-container ${styles.Humanizer__positionWrapper}`}
                >
                    <div className={styles.Humanizer__textContainer}>
                        <h1 className={styles.Humanizer__title}>
                            <Trans
                                i18nKey="humanizer.title_bypass"
                                components={{
                                    transparent: <TransparentText />,
                                }}
                            />
                        </h1>
                        <p className={styles.Humanizer__description}>
                            {t(`humanizer.description_bypass`)}
                        </p>
                    </div>
                    <HumanizerInput className={styles.Humanizer__input} />
                    <div className={styles.Humanizer__bottomTitle_container}>
                        <p className={styles.Humanizer__bottomTitle}>
                            {t(`humanizer.bottom.title`)}
                        </p>
                        <button
                            onClick={() => {
                                if (localStorage.getItem('token')) {
                                    navigate('/generation/shadow-chat')
                                } else {
                                    navigate('/auth')
                                }
                            }}
                        >
                             {t(`humanizer.bottom.btn`)}
                            <img src={sparkle} alt="" />
                        </button>
                        {/* <ShadowChatIcon /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
