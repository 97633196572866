import { Header, Footer, Header_shadowgpt } from '@components'
import {
    Humanizer,
    PastRequests,
    DodgeAiChekers,
    ChooseUsSection,
    ShadowsReference,
    ShadowGPTReview,
    FAQBlock,
    PricingBlock,
    StayInShadowBlock,
    Auth,
    GenerationBlock,
    Pricing,
    ShadowGPT,
    Billings,
    Support,
    AccountDetails,
    History,
    ErrorPage,
    TermsAndPrivicyPages,
    TermsAndConditions,
    PrivacyPolicy,
} from '@pages'
import {
    Humanizer_shadowgpt,
    PastRequests_shadowgpt,
    DodgeAiChekers_shadowgpt,
    ChooseUsSection_shadowgpt,
    ShadowsReference_shadowgpt,
    ShadowGPTReview_shadowgpt,
    FAQBlock_shadowgpt,
    StayInShadowBlock_shadowgpt,
} from '@pages'
import {
    TextWall_bypass,
    Humanizer_bypass,
    FAQBlock_bypass,
    StayInShadowBlock_bypass,
} from '@pages'
import {
    Humanizer_gptzero,
    FAQBlock_gptzero,
    TextWall_gptzero
} from '@pages'
import {
    Humanizer_aichanger,
    FAQBlock_aichanger,
    TextWall_aichanger
} from '@pages'
import { useUnit } from 'effector-react'
import React from 'react'
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './App.module.scss'
import { userFx, userLanaguageEvent } from './features/app/model'
import { getGclid, storeGclidInLocalStorage } from './utils/gclid';

const privateRoute = [
    {
        path: '/support',
        element: <Support />,
    },
    {
        path: '/generation/',
        element: <GenerationBlock />,
        children: [
            {
                path: '/generation/shadow-chat',
                element: <ShadowGPT />,
            },
            {
                path: '/generation/billings',
                element: <Billings />,
            },
            {
                path: '/generation/account-details',
                element: <AccountDetails />,
            },
            {
                path: '/generation/history',
                element: <History />,
            },
        ],
    },
]
const publicRoute = [
    {
        path: '/',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer />
                <DodgeAiChekers />
                <ShadowsReference />
                <ShadowGPTReview />
                <PastRequests />
                <ChooseUsSection />
                <FAQBlock />
                <PricingBlock />
                <StayInShadowBlock />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/shadowgpt',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_shadowgpt />
                <DodgeAiChekers_shadowgpt />
                <ShadowsReference_shadowgpt />
                <PastRequests_shadowgpt />
                <ShadowGPTReview_shadowgpt />
                <ChooseUsSection_shadowgpt />
                <FAQBlock_shadowgpt />
                <PricingBlock />
                <StayInShadowBlock_shadowgpt />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/bypass-ai-detection',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_bypass />
                <TextWall_bypass/>
                <FAQBlock_bypass/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/bypass-gptzero',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_gptzero />
                <TextWall_gptzero/>
                <FAQBlock_gptzero/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/ai-changer-to-human',
        element: (
            <>
                <Header_shadowgpt />
                <Humanizer_aichanger />
                <TextWall_aichanger/>
                <FAQBlock_aichanger/>
                <PricingBlock />
                <StayInShadowBlock_bypass />
                <Footer />
            </>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: '/pricing',
        element: <Pricing />,
    },
    {
        path: '/auth',
        element: <Auth />,
    },
    {
        path: '/terms',
        element: <TermsAndPrivicyPages />,
        children: [
            {
                element: <TermsAndConditions />,
                path: '/terms/conditions',
            },
            {
                element: <PrivacyPolicy />,
                path: '/terms/privacy',
            },
        ],
    },
    {
        path: '*',
        element: <Auth />,
    },
]

function App() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!);
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID })
    }

    let token = localStorage.getItem('token')
    const userRequest = useUnit(userFx)

    const { i18n } = useTranslation()

    React.useEffect(() => {
        const gclid = getGclid();
        if (gclid) {
            storeGclidInLocalStorage(gclid);
        }
    }, []);
    
    React.useEffect(() => {
        if (localStorage.getItem('token'))
            userRequest().then((res: any) => {
                if (Object.keys(res)) {
                    userLanaguageEvent(res.language.code.toLowerCase())
                    i18n.changeLanguage(res.language.code.toLowerCase())
                }
            })
    }, [token, userRequest])

    return (
        <>
            <div
                style={{ position: 'relative', top: '50px', zIndex: '100000' }}
            >
                <ToastContainer
                    position="top-center"
                    theme="dark"
                    autoClose={4000}
                    hideProgressBar={true}
                />
            </div>
            <div className={styles.background}>
                <RouterProvider
                    router={createBrowserRouter(
                        localStorage.getItem('token')?.length
                            ? [...publicRoute, ...privateRoute]
                            : [...publicRoute]
                    )}
                />
            </div>
        </>
    )
}

export default App
